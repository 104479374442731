<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/chancellor' }">
        <app-i18n code="entities.chancellor.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.chancellor.view.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.chancellor.view.title"></app-i18n>
      </h1>

      <div class="app-page-spinner" v-if="loading" v-loading="loading"></div>

      <app-chancellor-view-toolbar v-if="record"></app-chancellor-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        

        <app-view-item-relation-to-many
          :label="fields.faculty.label"
          :readPermission="fields.faculty.readPermission"
           :navigatePermission="fields.faculty.navigatePermission"
          :url="fields.faculty.viewUrl"
          :value="presenter(record, 'faculty')"
        ></app-view-item-relation-to-many>

        <app-view-item-text :label="fields.contactEmail.label" :value="presenter(record, 'contactEmail')"></app-view-item-text>

        <app-view-item-text :label="fields.contactPerson.label" :value="presenter(record, 'contactPerson')"></app-view-item-text>

        <app-view-item-text :label="fields.phoneNumber.label" :value="presenter(record, 'phoneNumber')"></app-view-item-text>

        <app-view-item-text :label="fields.createdAt.label" :value="presenter(record, 'createdAt')"></app-view-item-text>

        <app-view-item-text :label="fields.updatedAt.label" :value="presenter(record, 'updatedAt')"></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChancellorViewToolbar from '@/modules/chancellor/components/chancellor-view-toolbar.vue';
import { ChancellorModel } from '@/modules/chancellor/chancellor-model';

const { fields } = ChancellorModel;

export default {
  name: 'app-chancellor-view-page',

  props: ['id'],

  components: {
    [ChancellorViewToolbar.name]: ChancellorViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'chancellor/view/record',
      loading: 'chancellor/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'chancellor/view/doFind',
    }),

    presenter(record, fieldName) {
      return ChancellorModel.presenter(record, fieldName);
    },
  },
};
</script>
